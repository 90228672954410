import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import translations from '$lib/i18n/translations'
import { LocaleValidator } from '$lib/validators'
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

const locale = LocaleValidator.safeParse(navigator.language).success ? LocaleValidator.parse(navigator.language) : 'en-US'

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you dont want to use Session Replay, just remove the line below:
  integrations: [
    replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: 'light',
      showName: false,
      showBranding: false,
      triggerLabel: translations[locale].feedback_widget_trigger_label,
      triggerAriaLabel: translations[locale].feedback_widget_trigger_aria_label,
      formTitle: translations[locale].feedback_widget_form_title,
      submitButtonLabel: translations[locale].feedback_widget_submit_button_label,
      cancelButtonLabel: translations[locale].feedback_widget_cancel_button_label,
      confirmButtonLabel: translations[locale].feedback_widget_confirm_button_label,
      addScreenshotButtonLabel: translations[locale].feedback_widget_add_screenshot_button_label,
      removeScreenshotButtonLabel: translations[locale].feedback_widget_remove_screenshot_button_label,
      nameLabel: translations[locale].feedback_widget_name_label,
      namePlaceholder: translations[locale].feedback_widget_name_placeholder,
      emailLabel: translations[locale].feedback_widget_email_label,
      emailPlaceholder: translations[locale].feedback_widget_email_placeholder,
      isRequiredLabel: translations[locale].feedback_widget_is_required_label,
      messageLabel: translations[locale].feedback_widget_message_label,
      messagePlaceholder: translations[locale].feedback_widget_message_placeholder,
      successMessageText: translations[locale].feedback_widget_success_message_text,
    }),
  ],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
