import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [];

export const dictionary = {
		"/": [9],
		"/admin-dashboard": [10,[2]],
		"/admin-dashboard/ai-campaigns": [11,[2]],
		"/admin-dashboard/shops": [12,[2]],
		"/admin-dashboard/shops/create": [13,[2]],
		"/admin-dashboard/users": [14,[2]],
		"/admin-dashboard/users/create": [15,[2]],
		"/admin-dashboard/users/edit/[user_id]": [16,[2]],
		"/admin-dashboard/webfonts": [17,[2]],
		"/admin-dashboard/webfonts/create": [18,[2]],
		"/admin-dashboard/webfonts/edit/[webfont_id]": [19,[2]],
		"/login": [20],
		"/shops": [21],
		"/shops/[shop_id]": [22,[3]],
		"/shops/[shop_id]/admin-preferences": [23,[3]],
		"/shops/[shop_id]/emails": [24,[3]],
		"/shops/[shop_id]/emails/[email_id]": [25,[3,4]],
		"/shops/[shop_id]/emails/[email_id]/variants": [26,[3,4]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]": [27,[3,4,5]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create": [28,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/discount-code": [29,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/error": [30,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/has-promotion": [31,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/images": [32,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/kind": [33,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/link": [34,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/notes": [35,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/preview": [36,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/products": [37,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/target": [38,[3,4,5,6]],
		"/shops/[shop_id]/emails/[email_id]/variants/[variant_id]/create/topic": [39,[3,4,5,6]],
		"/shops/[shop_id]/flows": [40,[3]],
		"/shops/[shop_id]/flows/[flow_id]": [41,[3,7]],
		"/shops/[shop_id]/settings": [42,[3]],
		"/shops/[shop_id]/templates": [43,[3,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';